import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Login.module.scss';
import Loading from '~/components/Loading';
import { requestLoginUser } from '~/services/auth';
import CusButton from '~/components/CusButton';
import { closeModal } from '~/redux/reducer/module';
import { loginSuccess } from '~/redux/reducer/auth';
import { dispatchPostError } from '~/redux/reducer/error';

import imageBgLogin from '~/assets/image/login_bg.png';
import imageFreeFireIcon from '~/assets/image/freefire.png';

const cx = classNames.bind(styles);

function Login() {
    const [uid, setUid] = useState('');
    const [valid, setValid] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogin = async () => {
        setLoading(true);
        if (uid.length < 8 || uid.length > 11) {
            setValid('ID người chơi không hợp lệ');
            return setLoading(false);
        }

        const data = {
            account_id: uid,
        };

        const result = await requestLoginUser(data);

        setLoading(false);
        if (!result) {
            setValid('Lỗi máy chủ vui lòng thử lại');
        } else if (result.status === 403) {
            localStorage.removeItem('_re');
            navigate('/');
            window.location.reload();
        } else if (result.status === 401) {
            dispatch(dispatchPostError(result));
        } else if (result.status === 200) {
            dispatch(loginSuccess(result.data));
            dispatch(closeModal());
            document.body.classList.remove('open');
        } else if (result.error === 'invalid_id') {
            setValid('ID người chơi không hợp lệ');
        } else if (result.error) {
            setValid(result.error);
        } else {
            setValid('Máy chủ đăng nhập đang quá tải');
        }
    };

    const handleClose = async () => {
        if (loading) return;
        dispatch(closeModal());
        document.body.classList.remove('open');
    };

    return (
        <Fragment>
            <div className={cx('wrapper')}>
                <div className={cx('container')}>
                    {loading ? (
                        <Loading active />
                    ) : (
                        <Fragment>
                            <div className={cx('close')} onClick={handleClose}>
                                <div className={cx('close-icon')}></div>
                            </div>
                            <div className={cx('heading-bg', 'heading')}>
                                <div>
                                    <img className={cx('heading-bg')} alt="Heading" src={imageBgLogin} />
                                </div>
                                <div>
                                    <img src={imageFreeFireIcon} alt="Free Fire" className={cx('icon')} />
                                </div>
                                <div className={cx('ig')}>ID đăng nhập</div>
                            </div>
                            <div className={cx('body')}>
                                <div className={cx('from')}>
                                    <input
                                        type="text"
                                        className={cx('input')}
                                        placeholder="ID người chơi"
                                        maxLength={64}
                                        value={uid}
                                        onChange={(e) => {
                                            setUid(e.target.value);
                                            valid && setValid('');
                                        }}
                                    />

                                    {valid && (
                                        <div className={cx('error')}>
                                            <div className={cx('message')}>{valid}</div>
                                        </div>
                                    )}

                                    <div className={cx('btn-login')}>
                                        <CusButton onClick={handleLogin} text="Đăng nhập" />
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>

            <div className={cx('overlay')} onClick={handleClose}></div>
        </Fragment>
    );
}

export default Login;
