const isNumericString = (input) => {
    return /^[0-9]+$/.test(input);
};

export const isValidAccountId = (uid) => {
    return uid.length >= 8 && uid.length <= 11 && isNumericString(uid);
};

export const isValidFormPay = (number) => {
    return number.trim() && isNumericString(number.trim());
};
