import classNames from 'classnames/bind';
import styles from './Terms.module.scss';
import LineWrap from '~/components/LineWrap';
import CusButton from '~/components/CusButton';

const cx = classNames.bind(styles);

function Terms() {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('button')}>
                <CusButton back />
            </div>

            <LineWrap title="Điều Khoản Dịch Vụ" />

            <div className={cx('content')}>
                <ol>
                    <li>
                        <strong>GIỚI THIỆU</strong>

                        <ol>
                            <li>
                                Chào mừng bạn đến với trang web napthe.vn của Công ty Cổ phần Phát triển Thể Thao điện
                                tử Việt Nam ("Trang Web"). Vui lòng đọc kỹ các Điều Khoản Dịch Vụ sau đây trước khi sử
                                dụng Trang Web này hoặc trước khi mở một tài khoản ("Tài Khoản") để bạn biết được các
                                quyền lợi và nghĩa vụ hợp pháp của mình liên quan đến Công ty Cổ phần Phát triển Thể
                                thao điện tử Việt Nam và các công ty liên kết và các công ty con của Công ty Cổ phần
                                Phát triển Thể thao điện tử Việt Nam (gọi riêng và gọi chung là "chúng tôi" hoặc "của
                                chúng tôi").
                            </li>
                            <li>
                                "Các Dịch Vụ" chúng tôi cung cấp hoặc phát hành bao gồm (a) Trang Web này, (b) các dịch
                                vụ được cung cấp bởi Trang Web và bởi phần mềm khách hàng của chúng tôi được cung cấp
                                thông qua Trang Web, và (c) mọi thông tin, các trang web được liên kết, các tính năng,
                                dữ liệu, văn bản, hình ảnh, hình chụp, đồ họa, nhạc, âm thanh, video, thông báo, tag,
                                nội dung, lập trình, phần mềm, các dịch vụ ứng dụng (bao gồm nhưng không giới hạn ở bất
                                kỳ dịch vụ ứng dụng di động nào) hoặc các tài liệu khác được cung cấp thông qua Trang
                                Web hoặc các dịch vụ liên quan của nó ("Nội Dung"). Bất kỳ tính năng mới nào được thêm
                                vào hoặc để tăng cường Các Dịch Vụ đều phải tuân thủ các Điều Khoản Dịch Vụ này.
                            </li>
                            <li>
                                BẰNG VIỆC SỬ DỤNG CÁC DỊCH VỤ HOẶC MỞ MỘT TÀI KHOẢN, BẠN CHO BIẾT RẰNG BẠN CHẤP NHẬN,
                                KHÔNG RÚT LẠI, CÁC ĐIỀU KHOẢN DỊCH VỤ NÀY. NẾU BẠN KHÔNG ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN NÀY,
                                VUI LÒNG KHÔNG SỬ DỤNG CÁC DỊCH VỤ CỦA CHÚNG TÔI HAY TRUY CẬP TRANG WEB. NẾU BẠN DƯỚI 18
                                TUỔI HOẶC "ĐỘ TUỔI TRƯỞNG THÀNH "PHÙ HỢP Ở NƠI BẠN SỐNG, BẠN PHẢI XIN PHÉP CHA MẸ HOẶC
                                NGƯỜI GIÁM HỘ HỢP PHÁP ĐỂ MỞ MỘT TÀI KHOẢN VÀ CHA MẸ HOẶC NGƯỜI GIÁM HỘ HỢP PHÁP PHẢI
                                ĐỒNG Ý VỚI CÁC ĐIỀU KHOẢN DỊCH VỤ NÀY. NẾU BẠN KHÔNG BIẾT BẠN CÓ THUỘC "ĐỘ TUỔI TRƯỞNG
                                THÀNH" Ở NƠI BẠN SỐNG HAY KHÔNG, HOẶC KHÔNG HIỂU PHẦN NÀY, VUI LÒNG KHÔNG TẠO TÀI KHOẢN
                                CHO ĐẾN KHI BẠN ĐÃ NHỜ CHA MẸ HOẶC NGƯỜI GIÁM HỘ HỢP PHÁP CỦA BẠN GIÚP ĐỠ. NẾU BẠN LÀ
                                CHA MẸ HOẶC NGƯỜI GIÁM HỘ HỢP PHÁP CỦA MỘT TRẺ VỊ THÀNH NIÊN MUỐN TẠO MỘT TÀI KHOẢN, BẠN
                                PHẢI CHẤP NHẬN CÁC ĐIỀU KHOẢN DỊCH VỤ NÀY THAY MẶT CHO TRẺ VỊ THÀNH NIÊN ĐÓ VÀ BẠN SẼ
                                CHỊU TRÁCH NHIỆM ĐỐI VỚI TẤT CẢ HOẠT ĐỘNG SỬ DỤNG TÀI KHOẢN HAY CÁC DỊCH VỤ, BAO GỒM CÁC
                                GIAO DỊCH MUA HÀNG DO TRẺ VỊ THÀNH NIÊN THỰC HIỆN, CHO DÙ TÀI KHOẢN CỦA TRẺ VỊ THÀNH
                                NIÊN ĐÓ ĐƯỢC MỞ VÀO LÚC NÀY HAY ĐƯỢC TẠO SAU NÀY VÀ CHO DÙ TRẺ VỊ THÀNH NIÊN CÓ ĐƯỢC BẠN
                                GIÁM SÁT TRONG GIAO DỊCH MUA HÀNG ĐÓ HAY KHÔNG.
                            </li>
                            <li>
                                Chúng tôi có quyền chỉnh sửa các Điều Khoản Dịch Vụ này vào bất kỳ lúc nào mà không cần
                                thông báo cho người dùng. Việc bạn tiếp tục sử dụng Các Dịch Vụ, Trang Web này, hoặc Tài
                                Khoản của bạn sẽ được xem là sự chấp nhận, không rút lại đối với các điều khoản chỉnh
                                sửa đó.
                            </li>
                            <li>
                                Chúng tôi có quyền thay đổi, điều chỉnh, đình chỉ hoặc ngưng bất kỳ phần nào của Trang
                                Web này hoặc Các Dịch Vụ vào bất kỳ lúc nào. Chúng tôi có thể ra mắt Các Dịch Vụ nhất
                                định hoặc các tính năng của chúng trong một phiên bản beta, phiên bản này có thể không
                                hoạt động chính xác hoặc theo cùng cách như phiên bản cuối cùng, và chúng tôi sẽ không
                                chịu trách nhiệm pháp lý trong các trường hợp đó. Chúng tôi cũng có thể toàn quyền áp
                                dụng giới hạn đối với các tính năng nhất định hoặc hạn chế quyền truy cập của bạn đối
                                với một phần hoặc toàn bộ Trang Web hoặc Các Dịch Vụ mà không cần thông báo hoặc phải
                                chịu trách nhiệm pháp lý.
                            </li>
                            <li>
                                Chúng tôi có quyền từ chối cho phép bạn truy cập Trang Web hoặc Các Dịch Vụ hoặc cho
                                phép bạn mở Tài Khoản vì bất kỳ lý do gì.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>BẢO MẬT</strong>

                        <ol>
                            <li>
                                Chúng tôi rất coi trọng việc bảo mật thông tin của bạn. Để bảo vệ các quyền của bạn một
                                cách hiệu quả hơn, chúng tôi đã cung cấp Chính Sách Bảo Mật tại napthe.vn để giải thích
                                chi tiết về các cách thức bảo mật thông tin của chúng tôi. Vui lòng xem Chính Sách Bảo
                                Mật để hiểu được cách thức chúng tôi thu thập và sử dụng thông tin liên kết với Tài
                                Khoản của bạn và/hoặc việc bạn sử dụng Các Dịch Vụ. Bằng việc sử dụng Các Dịch Vụ hoặc
                                đồng ý với các Điều Khoản Dịch Vụ này, bạn đồng ý cho phép chúng tôi thu thập, sử dụng,
                                tiết lộ và/hoặc xử lý Nội Dung và dữ liệu cá nhân của bạn như mô tả trong Chính Sách Bảo
                                Mật này.
                            </li>
                            <li>
                                Người dùng nào lưu giữ dữ liệu cá nhân của một người dùng khác ("Bên Nhận Thông Tin")
                                phải (i) tuân thủ mọi điều luật bảo vệ dữ liệu cá nhân hiện hành; (ii) cho phép người
                                dùng kia ("Bên Tiết Lộ Thông Tin") tự xóa tên mình ra khỏi cơ sở dữ liệu của Bên Nhận
                                Thông Tin; và (iii) cho phép Bên Tiết Lộ Thông Tin xem các thông tin đã được Bên Nhận
                                Thông Tin thu thập về họ.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>GIỚI HẠN VỀ QUYỀN SỬ DỤNG</strong>

                        <ol>
                            <li>
                                Chúng tôi cấp cho bạn quyền sử dụng có giới hạn để truy cập và sử dụng Các Dịch Vụ tuân
                                theo các điều khoản và điều kiện của Điều Khoản Dịch Vụ này vì mục đích sử dụng cá nhân.
                                Việc cấp quyền này không cho phép bạn sử dụng vì bất kỳ mục đích thương mại nào hay sử
                                dụng vì mục đích phái sinh đối với Các Dịch Vụ này (bao gồm nhưng không giới hạn ở bất
                                kỳ yếu tố riêng lẻ nào hay Nội Dung của nó). Tất cả Nội Dung, thương hiệu, nhãn hiệu
                                dịch vụ, tên thương hiệu, logo và tài sản trí tuệ khác được hiển thị trên Trang Web là
                                tài sản của chúng tôi và của các chủ sở hữu bên thứ ba được chỉ rõ trên Trang Web, nếu
                                có. Bất kỳ đối tượng nào truy cập vào Trang Web đều không có quyền hoặc được cấp phép
                                một cách trực tiếp hoặc gián tiếp sử dụng hoặc sao chép bất kỳ Nội dung, thương hiệu,
                                nhãn hiệu dịch vụ, tên thương hiệu, logo hay bất kỳ tài sản trí tuệ nào khác và cũng
                                không có đối tượng nào truy cập vào Trạng Web được yêu cầu bất kỳ quyền, quyền sở hữu
                                hay quyền lợi nào liên quan đến các đối tượng trên. Bằng việc sử dụng hoặc truy cập Các
                                Dịch Vụ, bạn đồng ý tuân thủ các qui định pháp luật về sở hữu trí tuệ hiện hành đối với
                                vấn đề bảo hộ bản quyền, thương hiệu, nhãn hiệu dịch vụ, Các Dịch Vụ, Trang Web và Nội
                                Dung của nó. Bạn đồng ý không sao chép, phát tán, tái xuất bản, gửi, trưng bày công
                                khai, trình diễn công khai, điều chỉnh, sửa đổi, cho thuê, bán, hay tạo phó bản của bất
                                kỳ phần nào của Các Dịch Vụ, Trang Web hoặc Nội Dung của nó. Nếu không có sự đồng ý
                                trước bằng văn bản của chúng tôi, bạn cũng không được nhân bản hoặc chỉnh sửa một phần
                                hay toàn bộ nội dung của Trang Web này trên máy chủ khác hoặc như một phần của bất kỳ
                                trang web nào khác. Ngoài ra, bạn đồng ý rằng bạn sẽ không sử dụng bất kỳ công cụ robot,
                                spider hay bất kỳ thiết bị tự động hay quy trình thủ công nào khác để theo dõi hay sao
                                chép Nội Dung của chúng tôi, nếu không có sự đồng ý trước bằng văn bản của chúng tôi
                                (thỏa thuận này áp dụng cho các công cụ tìm kiếm cơ bản trên các website kết nối người
                                dùng trực tiếp đến website đó).
                            </li>
                            <li>
                                Chúng tôi hoan nghênh bạn liên kết đến Trang Web này từ trang web của bạn, miễn là trang
                                web của bạn không ngụ ý rằng chúng tôi ủng hộ hay liên kết với trang web của bạn. Bạn
                                thừa nhận rằng chúng tôi, vào bất kỳ lúc nào, có thể tự quyền quyết định về việc ngưng
                                cung cấp bất kỳ phần nào của Các Dịch Vụ mà không cần thông báo.
                            </li>
                        </ol>
                    </li>

                    <li>
                        <strong>PHẦN MỀM</strong>

                        <ol>
                            <li>
                                Trừ phi kèm theo một thỏa thuận cấp phép riêng, bất kỳ phần mềm nào được chúng tôi cung
                                cấp cho bạn như một phần của Các Dịch Vụ sẽ tuân thủ các điều khoản của Điều Khoản Dịch
                                Vụ này. Phần mềm được cấp phép sử dụng, không bán, và chúng tôi bảo lưu tất cả các quyền
                                đối với phần mềm mà chúng tôi không cấp. Bất kỳ lệnh hay mã nào của bên thứ ba, được
                                liên kết đến hoặc được tham chiếu từ Các Dịch Vụ, đều được cấp phép cho bạn bởi các bên
                                thứ ba sở hữu các mã script hoặc mã đó, không phải bởi chúng tôi.
                            </li>
                        </ol>
                    </li>
                    <li>
                        <strong>TÀI KHOẢN VÀ BẢO MẬT</strong>

                        <ol>
                            <li>
                                Một số chức năng trong Các Dịch Vụ của chúng tôi yêu cầu phải đăng ký một Tài Khoản bằng
                                cách chọn danh định người dùng duy nhất ("ID Người Dùng") và mật khẩu, và bằng cách cung
                                cấp một số thông tin cá nhân nhất định. Nếu bạn chọn một ID Người Dùng mà chúng tôi,
                                theo quyết định của mình, thấy là phản cảm hoặc không thích hợp, chúng tôi có quyền đình
                                chỉ hoặc chấm dứt Tài Khoản của bạn. Bạn có thể sử dụng Tài Khoản của mình để tiếp
                                cận/truy cập các sản phẩm, trang web hoặc dịch vụ mà chúng tôi đã cho phép truy cập hoặc
                                chúng tôi đã có liên kết hay hợp tác. Chúng tôi đã không xem xét, hay nhận trách nhiệm
                                đối với bất kỳ nội dung, chức năng, bảo mật, dịch vụ, chính sách bảo mật nào của bên thứ
                                ba, hay các hoạt động khác của các sản phẩm, trang web hoặc dịch vụ đó. Trong trường hợp
                                đó, điều khoản dịch vụ dành cho những sản phẩm, website, dịch vụ đó, gồm cả chính sách
                                bảo mật tương ứng, vẫn được áp dụng khi bạn sử dụng sản phẩm, website, dịch vụ đó ngay
                                cả khi những điều khoản bảo mật khác với Điều khoản dịch vụ/Chính sách bảo mật của chúng
                                tôi.
                            </li>
                            <li>
                                Bạn đồng ý (a) bảo mật mật khẩu của bạn và chỉ sử dụng ID Người Dùng và mật khẩu của bạn
                                khi đăng nhập, (b) đảm bảo rằng bạn đăng xuất ra khỏi tài khoản của mình vào cuối mỗi
                                phiên truy cập trên Trang Web, (c) thông báo ngay cho chúng tôi biết bất kỳ trường hợp
                                nào sử dụng trái phép ID Người Dùng và/hoặc mật khẩu của bạn, và (d) đảm bảo rằng thông
                                tin Tài Khoản của bạn là chính xác và cập nhật. Bạn hoàn toàn chịu trách nhiệm đối với
                                mọi hoạt động diễn ra dưới tên ID Người Dùng và Tài Khoản của bạn ngay cả khi các hoạt
                                động hay việc sử dụng đó không phải do bạn thực hiện. Chúng tôi sẽ không chịu trách
                                nhiệm pháp lý đối với bất kỳ tổn thất hay thiệt hại nào phát sinh từ việc mật khẩu của
                                bạn bị sử dụng trái phép hoặc việc bạn không tuân thủ điều khoản này.
                            </li>
                            <li>
                                Bạn đồng ý rằng chúng tôi có thể, vì bất kỳ lý do gì, tự quyền quyết định và không cần
                                thông báo hay chịu trách nhiệm pháp lý đối với bạn hay bất kỳ bên thứ ba nào, ngay lập
                                tức chấm dứt Tài Khoản và ID Người Dùng của bạn, và xóa hay gỡ bỏ bất kỳ Nội Dung nào
                                liên kết với Tài Khoản và ID Người Dùng của bạn ra khỏi Trang Web. Căn cứ chấm dứt tài
                                khoản có thể bao gồm, nhưng không giới hạn (a) tài khoản không hoạt động trong thời gian
                                dài, (b) vi phạm nội dung hoặc tinh thần của các Điều Khoản Dịch Vụ này, (c) có hành vi
                                gian lận, quấy rối, lăng mạ, đe dọa hoặc xúc phạm hoặc (d) có hành vi gây hại cho người
                                dùng khác, các bên thứ ba, hoặc lợi ích kinh doanh của chúng tôi. Hành vi sử dụng Tài
                                Khoản cho mục đích phi pháp, gian lận, quấy rối, lăng mạ, đe dọa hay xúc phạm có thể bị
                                báo cho các cơ quan thực thi pháp luật mà không cần thông báo cho bạn. Nếu bạn nộp đơn
                                khiếu kiện (cho dù nguyên nhân kiện là gì) hoặc chống lại chúng tôi, hoặc liên quan đến
                                chúng tôi dưới bất kỳ hình thức nào, thì chúng tôi có thể chấm dứt Tài Khoản của bạn.
                            </li>
                            <li>
                                Nếu Tài Khoản của bạn không hoạt động (có nghĩa là bạn đã không đăng nhập vào Tài Khoản
                                của mình) quá thời hạn 6 tháng, chúng tôi có quyền xóa hoặc vô hiệu Tài Khoản của bạn và
                                số Sò trong Tài khoản của bạn sẽ bị mất.
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div>
    );
}

export default Terms;
