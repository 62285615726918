import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import styles from './Notify.module.scss';
import CusButton from '../CusButton/CusButton';

const cx = classNames.bind(styles);

function Notify({ onClick }) {
    const { notifyContent } = useSelector((state) => state.setting);

    return (
        <div>
            <div className={cx('container')}>
                <div className={cx('content')}>
                    <div>
                        <img className={cx('image')} src={notifyContent} alt="Banner" />
                    </div>

                    <div className={cx('close')}>
                        <CusButton text="Đóng thông báo" onClick={onClick} />
                    </div>
                </div>
            </div>
            <div className={cx('overlay')} onClick={onClick}></div>
        </div>
    );
}

export default Notify;
