import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Transaction from '../Transaction';
import styles from './PayCard.module.scss';
import { requestGetChannels } from '~/services/card';
import { logoutSuccess } from '~/redux/reducer/auth';
import MobilePay from '~/components/Mobile/MobilePay';
import { dispatchPostError } from '~/redux/reducer/error';
import { postCurrentPay, postMemberPay } from '~/redux/reducer/pay';

const cx = classNames.bind(styles);

function PayCard() {
    const [cards, setCards] = useState([]);
    const [active, setActive] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentUser } = useSelector((state) => state.auth);
    const { userLogin } = useSelector((state) => state.setting);

    useEffect(() => {
        const fetch = async () => {
            const source = isMobile ? 'mobile' : 'pc';
            const result = await requestGetChannels(source);

            if (result.status === 403) {
                localStorage.removeItem('_re');
                navigate('/');
                window.location.reload();
            } else if (result.status === 401) {
                dispatch(dispatchPostError(result));
            } else if (result.error === 'expires_access_key') {
                window.location.reload();
            } else if (result.status === 200) {
                setCards(result.data);
                dispatch(postMemberPay(result.cards));
                dispatch(postCurrentPay(result.data[0]));
            } else if (userLogin && currentUser) {
                dispatch(logoutSuccess());
            } else {
                navigate('/app');
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectCard = (card, index) => {
        if (card.active) {
            setActive(index);
            dispatch(postCurrentPay(card));
        }
    };

    return (
        <Fragment>
            {!isMobile ? (
                <div className={cx('wrapper')}>
                    <div className={cx('list')}>
                        {cards.map((card, index) => (
                            <div className={cx('item')} key={index} onClick={() => handleSelectCard(card, index)}>
                                <div className={cx('card', { active: active === index, disable: !card.active })}>
                                    <div className={cx('image')}>
                                        <img src={card.image_url} alt={card.title} />
                                    </div>

                                    {active === index && (
                                        <div className={cx('arrow')}>
                                            <div className={cx('icon')}></div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    <Transaction />
                </div>
            ) : (
                <MobilePay cards={cards} />
            )}
        </Fragment>
    );
}

export default PayCard;
