import moment from 'moment';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../Loading';
import ResultPay from '../ResultPay';
import CusButton from '../CusButton';
import styles from './FormPay.module.scss';
import { requestPostCard } from '~/services/card';
import { dispatchPostError } from '~/redux/reducer/error';
import { convertCurrency, convertToDateTime } from '~/configs';
import { isValidAccountId, isValidFormPay } from './validation';

import imageDiamond from '~/assets/image/diamond.png';
import imageMoths from '~/assets/image/card-moths.png';

const cx = classNames.bind(styles);

function FormPay() {
    const [uid, setUid] = useState('');
    const [code, setCode] = useState('');
    const [data, setData] = useState(null);
    const [valid, setValid] = useState('');
    const [serial, setSerial] = useState('');
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);

    const [time, setTime] = useState(false);
    const [textBtn, setTextBtn] = useState('Xử lý thanh toán');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);
    const { userLogin } = useSelector((state) => state.setting);
    const { selected, current } = useSelector((state) => state.pay);

    useEffect(() => {
        if (!selected || !current) {
            navigate('/app');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, current]);

    useEffect(() => {
        if (active) {
            setCode('');
            setSerial('');
            setActive(false);
        }

        setValid('');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const currentTimeString = moment(new Date()).format('HH:mm:ss');
    const timeoutTimeString = localStorage.getItem('timeout');

    const currentTime = convertToDateTime(currentTimeString);
    const timeoutTime = convertToDateTime(timeoutTimeString);
    const second = currentTime - timeoutTime;
    const currentSecond = second / 1000;

    useEffect(() => {
        if (timeoutTimeString) {
            if (currentSecond > 30) {
                localStorage.removeItem('timeout');
            } else {
                // Bắt đầu đếm ngược từ 30 giây
                let milliSecond = 30 - currentSecond;
                setTextBtn(`Vui lòng đợi ${milliSecond}s`);

                // Gọi hàm sau mỗi giây
                const countdownInterval = setInterval(() => {
                    // Giảm giá trị milliSecond đi 1
                    milliSecond--;

                    // Cập nhật nội dung nút
                    setTextBtn(`Vui lòng đợi ${milliSecond}s`);

                    // Kiểm tra xem đã đếm ngược xong chưa
                    if (milliSecond === 0) {
                        // Dừng đếm ngược
                        clearInterval(countdownInterval);

                        // Thực hiện các công việc cần thiết khi đếm ngược xong
                        setTextBtn('Xử lý thanh toán');
                        localStorage.removeItem('timeout');
                    }
                }, 1000);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [time]);

    let title, image;
    if (selected?.status === 2) {
        title = '';
        image = imageMoths;
    } else {
        title = 'Kim cương ×';
        image = imageDiamond;
    }

    const handleConfirm = async () => {
        if (currentSecond > 30) {
            localStorage.removeItem('timeout');
            return;
        }
        if (!userLogin && !isValidAccountId(uid)) {
            return setValid('ID người chơi không hợp lệ');
        }
        if (!isValidFormPay(serial)) {
            return setValid('Serial thẻ không đúng định dạng');
        }
        if (!isValidFormPay(code)) {
            return setValid('Mã thẻ không đúng định dạng');
        }

        setLoading(true);
        let card = {
            type: selected?.type,
            telco: current?.product_code,
            amount: selected?.price,
            code,
            serial,
        };
        if (!userLogin) {
            card.account_id = uid;
        } else {
            card.account_id = currentUser.account_id;
        }

        const result = await requestPostCard(card);

        setLoading(false);
        if (result.error) {
            return setValid(result.error);
        }
        if (result.status === 403) {
            localStorage.removeItem('_re');
            navigate('/');
            window.location.reload();
        }
        if (result.status === 401) {
            dispatch(dispatchPostError(result));
        }

        const { amount, date, telco, transId } = result.data;
        setActive(true);

        if (result.status === 99) {
            // Set thời gian được nạp tiếp theo
            const currentTime = moment(new Date()).format('HH:mm:ss');
            localStorage.setItem('timeout', currentTime);
            setTime(true);

            setData({
                select: selected?.select,
                message: 'Đang được xử lý!',
                amount,
                telco,
                status: 99,
                date,
                transId,
                image,
                title,
            });
        } else if (result.status === 1) {
            setData({
                select: selected?.select,
                message: 'Giao dịch thành công',
                amount,
                telco,
                status: 1,
                date,
                transId,
                image,
                title,
            });
        } else if (result.status === 2) {
            setData({
                select: selected?.select,
                message: 'Thẻ sai mệnh giá',
                amount,
                telco,
                status: 2,
                date,
                transId,
                title,
                image,
            });
        } else {
            setData({
                select: selected?.select,
                message: 'Thẻ cào sai',
                amount,
                telco,
                status: 3,
                date,
                transId,
                title,
                image,
            });
        }
    };

    return (
        <div className={cx('wrapper')}>
            {loading && !active && <Loading height="50" />}

            {!loading && !active && (
                <div className={cx('container')}>
                    <div className={cx('title')}>Chi tiết giao dịch</div>

                    <div className={cx('content')}>
                        <div className={selected?.status === 2 ? cx('line', 'active') : cx('line')}>
                            <div className={cx('product')}>Sản phẩm được chọn</div>

                            <div className={cx('value')}>
                                <img src={image} alt={title} />
                                <span>
                                    {title} {selected?.select}
                                </span>
                            </div>
                        </div>
                        {selected?.status === 2 && (
                            <Fragment>
                                <div className={cx('description')}>{selected?.description}</div>
                                <div className={cx('line', 'not')}></div>
                            </Fragment>
                        )}
                        <div className={cx('line')}>
                            <div className={cx('product')}>Giá</div>

                            <div className={cx('value')}>{convertCurrency(selected?.price)}</div>
                        </div>
                        <div className={cx('line')}>
                            <div className={cx('product')}>Phương thức thanh toán</div>
                            <div className={cx('value')}>{current?.title}</div>
                        </div>
                        {!userLogin ? (
                            <Fragment>
                                <div className={cx('line', 'active')}>
                                    <div className={cx('product')}>ID người chơi</div>
                                </div>
                                <div className={cx('code')}>
                                    <input
                                        type="text"
                                        placeholder="Nhập ID người chơi"
                                        value={uid}
                                        onChange={(e) => {
                                            setUid(e.target.value);
                                            setValid('');
                                        }}
                                    />
                                </div>
                            </Fragment>
                        ) : (
                            <div className={cx('line')}>
                                <div className={cx('product')}>Tên tài khoản trong game</div>
                                <div className={cx('value')}>{currentUser?.nickname}</div>
                            </div>
                        )}
                        <div className={cx('line', 'active')}>
                            <div className={cx('product')}>Serial nạp thẻ</div>
                        </div>
                        <div className={cx('code')}>
                            <input
                                type="text"
                                placeholder="Nhập serial thẻ của bạn"
                                value={serial}
                                onChange={(e) => {
                                    setSerial(e.target.value);
                                    setValid('');
                                }}
                            />
                        </div>
                        <div className={cx('line', 'active')}>
                            <div className={cx('product')}>Mã nạp thẻ</div>
                        </div>
                        <div className={cx('code')}>
                            <input
                                type="text"
                                placeholder="Nhập mã thẻ của bạn"
                                value={code}
                                onChange={(e) => {
                                    setCode(e.target.value);
                                    setValid('');
                                }}
                            />
                        </div>
                        {valid && <div className={cx('valid')}>{valid}</div>}
                        <div className={cx('btn-confirm')}>
                            <CusButton disable={textBtn === 'Xử lý thanh toán' ? false : true} onClick={handleConfirm} text={textBtn} />
                        </div>
                    </div>
                </div>
            )}

            {active && !loading && <ResultPay data={data} />}
        </div>
    );
}

export default FormPay;
