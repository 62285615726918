export const convertCurrency = (number) => {
    if (!number) return '0 VND';
    let formatted = new Intl.NumberFormat('de-DE', { style: 'decimal' }).format(number);
    formatted = formatted.replace(/\./g, ' ');
    return `${formatted} VND`;
};

export const convertDiamond = (number) => {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0;
};

export const convertToDateTime = (timeString) => {
    if (timeString) {
        const timeArray = timeString?.split(':');
        const dateTime = new Date();
        dateTime.setHours(timeArray[0]);
        dateTime.setMinutes(timeArray[1]);
        dateTime.setSeconds(timeArray[2]);
        return dateTime;
    }
};
