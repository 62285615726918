import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './Faq.module.scss';
import Sliders from '~/components/Sliders';
import LineWrap from '~/components/LineWrap';
import CusButton from '~/components/CusButton';

const cx = classNames.bind(styles);

function Faq() {
    return (
        <div className={cx('wrapper')}>
            <CusButton back />
            <Sliders />

            <LineWrap title="Hỏi & Đáp" />

            <div className={cx('content')}>
                <div className={cx('question')}>Mua thẻ Garena ở đâu?​</div>
                <div className={cx('reply')}>
                    Các bạn có thể mua thẻ Garena tại rất nhiều điểm bản phủ kín trên khắp 64 tỉnh thành. Để tìm điểm bán thuận tiện nhất
                    với bạn tham khảo:
                    <div className={cx('line')}></div>
                    Hệ thống điểm giao dịch ShopeePay bán thẻ Garena tại
                    <a href="https://info.daily.airpay.vn/dai-ly/diem-giao-dich" target="_blank" rel="noreferrer">
                        {' '}
                        ĐÂY
                    </a>
                    <br />
                    Hệ thống các cửa hàng Thế giới di động bán thẻ Garena tại
                    <a href="https://www.thegioididong.com/he-thong-sieu-thi-the-gioi-di-dong" target="_blank" rel="noreferrer">
                        {' '}
                        ĐÂY
                    </a>
                    <br />
                    Hệ thống các cửa hàng Thế giới di động bán thẻ Garena tại
                    <a href="https://www.thegioididong.com/he-thong-sieu-thi-the-gioi-di-dong" target="_blank" rel="noreferrer">
                        {' '}
                        ĐÂY
                    </a>
                    <br />
                    Hệ thống các cửa hàng Viễn Thông A bán thẻ Garena tại
                    <a href="https://vienthonga.vn/he-thong-trung-tam-smartphone.html" target="_blank" rel="noreferrer">
                        {' '}
                        ĐÂY
                    </a>
                    <div className={cx('line')}></div>
                    Hoặc bạn có thể sử dụng thẻ cào để thanh toán trực tiếp trên cổng <Link to="/app">{window.location.hostname}</Link> như
                    sau:
                    <div className={cx('line')}></div>
                    Bước 1: Truy cập trang theo đường dẫn <Link to="/app">{window.location.hostname}</Link>
                    <br />
                    Bước 2: Đăng nhập vào tài khoản của mình
                    <br />
                    Bước 3: Lựa chọn loại thẻ mà bạn muốn thanh toán
                    <br />
                    Bước 4: Tiến hành nạp thẻ cho game
                    <div className={cx('line')}></div>
                    Thông tin chi tiết, các bạn vui lòng xem tại <Link to="/app">ĐÂY</Link>
                    <div className={cx('line')}></div>
                    <div className={cx('question')}>Tại sao nên nạp tại kênh riêng của Garena</div>
                    Nạp game bằng thẻ cào tại kênh riêng của Garena sẽ được lợi hơn khi nạp tại kênh chính thức hình thức nạp chỉ thông qua
                    ID game rất đảm bảo an toàn và nhanh gọn không phức tạp
                </div>
            </div>
        </div>
    );
}

export default Faq;
