import { createSlice } from '@reduxjs/toolkit';

const sliderReducer = createSlice({
    name: 'slider',
    initialState: {
        sliders: [],
    },
    reducers: {
        setSliders: (state, action) => {
            state.sliders = action.payload;
        },
    },
});

export const { setSliders } = sliderReducer.actions;

export default sliderReducer.reducer;
