import request from '~/utils';

export const requestLoginUser = async (data) => {
    try {
        const res = await request.post('/auth/player_id_login', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestLogoutUser = async () => {
    try {
        const res = await request.post('/auth/player_id_logout');

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetSettings = async (region, language) => {
    try {
        const res = await request.get('/players', {
            params: {
                region,
                language,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestsAccess = async (q) => {
    try {
        const res = await request.post(
            '/players/requests',
            {},
            {
                params: {
                    q,
                },
            },
        );

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
