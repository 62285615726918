import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestsAccess } from '~/services/auth';

function Navigate({ children }) {
    const searchParams = new URLSearchParams(document.location.search);
    const q = searchParams.get('q');
    const navigate = useNavigate();

    useEffect(() => {
        const data = localStorage.getItem('_re');

        if (!data) {
            const fetch = async () => {
                const result = await requestsAccess(q);

                if (result?.status === 200) {
                    const date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    localStorage.setItem('_re', date);
                }

                navigate('/app');
            };
            fetch();
        } else {
            navigate('/app');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [q]);

    return children;
}

export default Navigate;
