import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import styles from './FreeFire.module.scss';
import { openModal } from '~/redux/reducer/module';

import imageFreeFireIcon from '~/assets/image/freefire.png';

const cx = classNames.bind(styles);

function FreeFire() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOpen = () => {
        if (isMobile) {
            navigate('/login');
        } else {
            dispatch(openModal());
            document.body.classList.add('open');
        }
    };

    return (
        <ul className={cx('wrapper')}>
            <li className={cx('item')}>
                <div className={cx('box')} onClick={handleOpen}>
                    <div className={cx('image')}>
                        <img src={imageFreeFireIcon} alt="Free Fire" />
                    </div>
                    <h2 className={cx('name')}>Free Fire</h2>
                </div>
            </li>
        </ul>
    );
}

export default FreeFire;
