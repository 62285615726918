import classNames from 'classnames/bind';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';

import ResultPay from '../ResultPay';
import GameIcon from '../Mobile/GameIcon';
import styles from './Billing.module.scss';
import TelcoItem from '../Mobile/Telco/TelcoItem';
import imageDiamond from '~/assets/image/diamond.png';
import { convertCurrency, convertDiamond } from '~/configs';

const cx = classNames.bind(styles);

function getCurrentTime() {
    let now = new Date(); // Lấy thời gian hiện tại
    let hours = now.getHours(); // Lấy giờ hiện tại
    let minutes = now.getMinutes(); // Lấy phút hiện tại
    let seconds = now.getSeconds(); // Lấy giây hiện tại
    let day = now.getDate(); // Lấy ngày hiện tại
    let month = now.getMonth() + 1; // Lấy tháng hiện tại, cộng thêm 1 vì tháng bắt đầu từ 0
    let year = now.getFullYear(); // Lấy năm hiện tại

    // Định dạng số để luôn hiển thị 2 chữ số
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    // Trả về chuỗi thời gian với định dạng mong muốn
    return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
}

function generateRandomNumber(length) {
    let randomNumber = '';
    while (randomNumber.length < length) {
        // Thêm số ngẫu nhiên vào chuỗi, cắt để chỉ lấy phần nguyên
        randomNumber += Math.floor(Math.random() * 10);
    }
    return randomNumber;
}

const dataTelcos = [
    {
        gift: 1400,
        value: 50000,
    },
    {
        gift: 2900,
        value: 100000,
    },
    {
        gift: 5950,
        value: 200000,
    },
    {
        gift: 8850,
        value: 300000,
    },
    {
        gift: 15250,
        value: 500000,
    },
    {
        gift: 30800,
        value: 1000000,
    },
];

function Billing() {
    const [data, setData] = useState(null);
    const [telcos, setTelcos] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (!isMobile) {
            navigate('/app');
        } else {
            setTelcos(dataTelcos);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelect = (telco) => {
        const data = {
            image: imageDiamond,
            amount: telco.value,
            date: getCurrentTime(),
            transId: generateRandomNumber(18),
            telco: 'Thẻ Viettel',
            title: 'Kim cương ×',
            select: convertDiamond(telco.gift),
            status: 1,
            message: 'Giao dịch thành công',
        };

        setData(data);
    };

    return (
        <div className={cx('wrapper')}>
            <GameIcon icon="Thẻ Viettel" />
            {data ? (
                <ResultPay data={data} />
            ) : (
                <Fragment>
                    <div className={cx('help')}>Chọn gói cần nạp</div>

                    <ul className={cx('list')}>
                        {telcos.map((telco, index) => (
                            <TelcoItem
                                key={index}
                                gift={convertDiamond(telco.gift)}
                                value={convertCurrency(telco.value)}
                                image={imageDiamond}
                                onClick={() => handleSelect(telco)}
                            />
                        ))}
                    </ul>
                </Fragment>
            )}
        </div>
    );
}

export default Billing;
