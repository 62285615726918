import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '~/components/Loading';
import styles from './MobileLogin.module.scss';
import CusButton from '~/components/CusButton';
import { requestLoginUser } from '~/services/auth';
import { loginSuccess } from '~/redux/reducer/auth';
import { dispatchPostError } from '~/redux/reducer/error';

import imageFreeFireIcon from '~/assets/image/freefire.png';

const cx = classNames.bind(styles);
function MobileLogin() {
    const [uid, setUid] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!isMobile) {
            navigate('/');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    useEffect(() => {
        if (currentUser) {
            navigate('/app');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    const handleLogin = async () => {
        setLoading(true);
        if (!uid) {
            setMessage('Vui lòng nhập ID người chơi');
            return setLoading(false);
        }
        if (uid.length < 8 || uid.length > 11) {
            setMessage('ID người chơi không hợp lệ');
            return setLoading(false);
        }

        const data = {
            account_id: uid,
        };

        const result = await requestLoginUser(data);

        setLoading(false);
        if (!result) {
            setMessage('Lỗi máy chủ vui lòng thử lại');
        } else if (result.status === 403) {
            localStorage.removeItem('_re');
            navigate('/');
            window.location.reload();
        } else if (result.status === 401) {
            dispatch(dispatchPostError(result));
        } else if (result.status === 200) {
            dispatch(loginSuccess(result.data));
            navigate('/app');
        } else if (result.error === 'invalid_id') {
            setMessage('ID người chơi không hợp lệ');
        } else if (result.error) {
            setMessage(result.error);
        } else {
            setMessage('Máy chủ đăng nhập đang quá tải');
        }
    };

    return (
        <div className={cx('wrapper')}>
            {loading ? (
                <Loading active />
            ) : (
                <Fragment>
                    <div className={cx('header')}>
                        <img src={imageFreeFireIcon} alt="Free Fire" />

                        <div className={cx('title')}>ID đăng nhập</div>
                    </div>
                    <div className={cx('form')}>
                        <div className={cx('form-control')}>
                            <input
                                type="text"
                                className={cx('input')}
                                placeholder="ID người chơi"
                                maxLength={64}
                                value={uid}
                                onChange={(e) => {
                                    setUid(e.target.value);
                                    setMessage('');
                                }}
                            />
                        </div>
                        {message && <div className={cx('message-error')}>{message}</div>}

                        <div className={cx('btn')}>
                            <CusButton text="Đăng nhập" onClick={handleLogin} />
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}

export default MobileLogin;
