import classNames from 'classnames/bind';

import FormPay from '~/components/FormPay';
import styles from './ConfirmPay.module.scss';
import GameIcon from '~/components/Mobile/GameIcon';

const cx = classNames.bind(styles);

function ConfirmPay() {
    return (
        <div className={cx('wrapper')}>
            <GameIcon />
            <FormPay />
        </div>
    );
}

export default ConfirmPay;
