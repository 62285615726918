import { createSlice } from '@reduxjs/toolkit';

const settingReducer = createSlice({
    name: 'setting',
    initialState: {
        userLogin: false,
        notifyContent: '',
        notifyStatus: false,
        websiteStatus: false,
        history_charging: false,
    },
    reducers: {
        dispatchSettings: (state, action) => {
            state.userLogin = action.payload.user_login;
            state.notifyStatus = action.payload.notify_status;
            state.websiteStatus = action.payload.website_status;
            state.notifyContent = action.payload.notify_content;
            state.history_charging = action.payload.history_charging;
        },
    },
});

export const { dispatchSettings } = settingReducer.actions;

export default settingReducer.reducer;
