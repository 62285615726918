import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './TelcoItem.module.scss';

const cx = classNames.bind(styles);

function TelcoItem({ onClick, image, gift, value, link }) {
    return (
        <li className={cx('item')} onClick={onClick}>
            <Link to={link}>
                <div className={cx('value')}>
                    <div className={cx('icon-diamond')}>
                        <img src={image} alt="Kim cương" />
                    </div>
                    <div className={cx('diamond')}>
                        <div className={cx('diamond-title')}>Kim Cương</div>
                        <div className={cx('diamond-value')}>{gift}</div>
                    </div>
                </div>
                <div className={cx('face-value')}>{value}</div>
            </Link>
        </li>
    );
}

export default TelcoItem;
