import classNames from 'classnames/bind';
import { Fragment, useState } from 'react';
import Tippy from '@tippyjs/react/headless';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

import styles from './Header.module.scss';
import { requestLogoutUser } from '~/services/auth';
import { logoutSuccess } from '~/redux/reducer/auth';
import { closeSidebar, openModal, openSidebar } from '~/redux/reducer/module';

const cx = classNames.bind(styles);

function Header() {
    const [active, setActive] = useState(false);
    const { sidebar } = useSelector((state) => state.module);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { currentUser } = useSelector((state) => state.auth);
    const { userLogin } = useSelector((state) => state.setting);

    const handleLogout = async () => {
        await requestLogoutUser();
        dispatch(logoutSuccess());
        navigate('/app');
        window.location.reload();
    };

    const handleSidebar = () => {
        if (sidebar) {
            dispatch(closeSidebar());
            document.body.classList.remove('open');
        } else {
            dispatch(openSidebar());
            document.body.classList.add('open');
        }
    };

    const handleModal = () => {
        dispatch(openModal());
        document.body.classList.add('open');
    };

    return (
        <header className={cx('wrapper')}>
            <div className={cx('container')}>
                <Link to="/app">
                    <div className={cx('logo')}></div>
                </Link>

                <div className={cx('help')}>
                    <a href="https://hotro.garena.vn/" target="_blank" rel="noreferrer" className={cx('help-contact')}>
                        Chăm sóc khách hàng
                    </a>
                    <Fragment>
                        <div className={cx('profile')}>
                            <Tippy
                                interactive
                                visible={active && (!userLogin || currentUser)}
                                appendTo={document.body}
                                onClickOutside={() => setActive(false)}
                                render={(attrs) => (
                                    <div className={cx('tippy', !userLogin && 'tippy-login')} tabIndex="-1" {...attrs}>
                                        {userLogin && (
                                            <div className={cx('info')}>
                                                <div className={cx('info-avt')}>
                                                    <img src={currentUser?.img_url} alt={currentUser?.nickname} />
                                                </div>
                                                <div className={cx('info-nick')}>{currentUser?.nickname}</div>
                                            </div>
                                        )}

                                        <Link className={cx('logout')} to="/histories" onClick={() => setActive(false)}>
                                            Lịch sử giao dịch
                                        </Link>
                                        {userLogin && (
                                            <button className={cx('logout')} onClick={handleLogout}>
                                                Thoát ID người chơi
                                            </button>
                                        )}
                                    </div>
                                )}
                            >
                                <div className={cx('user', !userLogin && 'user-icon')} onMouseEnter={() => setActive(true)}>
                                    {currentUser && userLogin ? (
                                        <Fragment>
                                            <div className={cx('avatar')}>
                                                <img src={currentUser.img_url} alt={currentUser.nickname} />
                                            </div>
                                            <div className={cx('nickname')}>{currentUser.nickname}</div>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {userLogin ? (
                                                <div className={cx('login')} onClick={handleModal}>
                                                    <div className={cx('icon-user')}></div>
                                                    <span>Đăng nhập</span>
                                                </div>
                                            ) : (
                                                <div className={cx('icon-bars')}>
                                                    <FontAwesomeIcon icon={faBars} />
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                </div>
                            </Tippy>
                        </div>
                    </Fragment>

                    <div className={cx('icon-mobile')} onClick={handleSidebar}>
                        {sidebar ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
