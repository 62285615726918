import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { convertCurrency } from '~/configs';
import styles from './Membership.module.scss';
import { postSelectPay } from '~/redux/reducer/pay';

const cx = classNames.bind(styles);

function Membership({ data }) {
    const [memberships, setMemberships] = useState([]);

    const dispatch = useDispatch();
    const { members, selected } = useSelector((state) => state.pay);

    useEffect(() => {
        if (data) {
            setMemberships(data);
        } else {
            setMemberships(members);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleSelect = (card, index) => {
        dispatch(
            postSelectPay({
                select: card.title,
                price: card.price,
                description: card.description,
                status: 2,
                index,
                type: 'T',
            }),
        );
    };

    const Wrapper = isMobile ? Link : 'div';

    return (
        <ul className={cx('wrapper')}>
            {memberships?.map((member, index) => (
                <li className={cx('item')} key={index} onClick={() => handleSelect(member, index)}>
                    <Wrapper to="/app/buy/pay">
                        <div className={cx('image')}>
                            <div
                                className={selected?.index === index && selected?.status === 2 ? cx('border', 'active') : cx('border')}
                            ></div>
                            <div>
                                <img src={member.image_url} alt={member.title} />
                            </div>
                            <div className={cx('discount')}>
                                <span>Hot</span>
                            </div>
                        </div>

                        <div className={selected?.index === index && selected?.status === 2 ? cx('name', 'active') : cx('name')}>
                            {member.title}
                        </div>
                        <div className={cx('price')}>{convertCurrency(member.price)}</div>
                        {member.discount > 0 && <div className={cx('old-price')}>{member.old_price}</div>}
                    </Wrapper>
                </li>
            ))}
        </ul>
    );
}

export default Membership;
