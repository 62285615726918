import classNames from 'classnames/bind';
import Loading from '~/components/Loading';
import styles from './Privacy.module.scss';
import LineWrap from '~/components/LineWrap';
import CusButton from '~/components/CusButton';

const cx = classNames.bind(styles);

function Privacy() {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('button')}>
                <CusButton back />
            </div>

            <LineWrap title="Chính Sách Bảo Mật" />

            <div className={cx('content')}>
                <Loading />
            </div>
        </div>
    );
}

export default Privacy;
