import { Fragment } from 'react';
import classNames from 'classnames/bind';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SideBar.module.scss';
import { requestLogoutUser } from '~/services/auth';
import { logoutSuccess } from '~/redux/reducer/auth';
import { closeSidebar } from '~/redux/reducer/module';

const cx = classNames.bind(styles);

function SideBar() {
    const { currentUser } = useSelector((state) => state.auth);
    const { userLogin } = useSelector((state) => state.setting);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSidebar = () => {
        dispatch(closeSidebar());
        document.body.classList.remove('open');
    };

    const handleLogin = () => {
        navigate('/login');
        dispatch(closeSidebar());
        document.body.classList.remove('open');
    };

    const handleLogout = async () => {
        await requestLogoutUser();
        dispatch(logoutSuccess());
        navigate('/app');
        window.location.reload();
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('overlay')} onClick={handleSidebar}></div>

            <div className={cx('content')}>
                {currentUser && userLogin && (
                    <div className={cx('profile')}>
                        <div className={cx('avatar')}></div>
                        <div className={cx('nickname')}>{currentUser.nickname}</div>
                    </div>
                )}

                {currentUser && userLogin ? (
                    <Fragment>
                        <Link className={cx('history')} to="/histories" onClick={handleSidebar}>
                            Lịch sử giao dịch
                        </Link>
                        <button className={cx('history')} onClick={handleLogout}>
                            Thoát chế độ ID người chơi
                        </button>
                    </Fragment>
                ) : (
                    <Fragment>
                        {userLogin ? (
                            <button className={cx('login')} onClick={handleLogin}>
                                <div className={cx('icon')}></div>
                                <span>Đăng nhập</span>
                            </button>
                        ) : (
                            <Fragment>
                                <Link className={cx('history')} to="/histories" onClick={handleSidebar}>
                                    Lịch sử giao dịch
                                </Link>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </div>
        </div>
    );
}

export default SideBar;
