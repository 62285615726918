import classNames from 'classnames/bind';

import styles from './LoadHistory.module.scss';
import HistoryItem from '~/layouts/components/History/HistoryItem';

const cx = classNames.bind(styles);

const histories = [
    {
        checked: false,
        date: '2024-06-03T14:35:20.759Z',
        gift: 1400,
        nickname: 'ｈａｎᴴᵀ✿',
        price: 50000,
        status: 3,
        telco: 'Thẻ Viettel',
        type: 'C',
        _id: '665dd4a8c7e03104ede6a01e',
    },
];

function LoadHistory() {
    return (
        <div className={cx('wrapper')}>
            {/* <div className={cx('header')}>
                <h3 className={cx('title')}>Lịch sử nạp người dùng</h3>
                <Link to="/histories">Xem lịch sử của bạn</Link>
            </div> */}
            <div className={cx('container')}>
                <div className={cx('table')}>
                    {/* <LoadHistoryItem /> */}
                    {histories.map((history) => (
                        <HistoryItem data={history} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default LoadHistory;
