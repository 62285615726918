const { createSlice } = require('@reduxjs/toolkit');

const payReducer = createSlice({
    name: 'pay',
    initialState: {
        current: null,
        members: [],
        selected: null,
    },
    reducers: {
        postCurrentPay: (state, action) => {
            state.current = action.payload;
            state.selected = null;
        },

        postMemberPay: (state, action) => {
            state.members = action.payload;
        },

        postSelectPay: (state, action) => {
            state.selected = action.payload;
        },
    },
});

export const { postCurrentPay, postMemberPay, postSelectPay } = payReducer.actions;

export default payReducer.reducer;
