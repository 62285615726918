import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './NotFound.module.scss';
import CusButton from '~/components/CusButton';

const cx = classNames.bind(styles);

function NotFound() {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <div className={cx('error')}>
                    <div className={cx('image')}></div>
                    <div className={cx('message')}>Trang yêu cầu không tìm thấy trên máy chủ này.</div>
                </div>
                <Link to="/app">
                    <CusButton text="Quay lại Trang chủ" />
                </Link>
            </div>
        </div>
    );
}

export default NotFound;
