import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

function Footer() {
    return (
        <footer className={cx('wrapper')}>
            <div className={cx('container')}>
                <div className={cx('content')}>
                    CÔNG TY CỔ PHẦN SHOPEEPAY <br /> Giấy CNĐKKD số 0103959912 | Cơ quan cấp: Phòng Đăng ký kinh doanh- Sở Kế hoạch và đầu
                    tư TP Hà Nội <br /> Địa chỉ trụ sở chính: Tầng 5, Tháp 2, Toà nhà Capital Place, Số 29 Liễu Giai, Phường Ngọc Khánh,
                    Quận Ba Đình, thành phố Hà Nội, Việt Nam
                </div>
                <div className={cx('list')}>
                    <div className={cx('item')}>
                        <Link to="/faq">Câu hỏi thường gặp</Link>
                    </div>
                    <div className={cx('item')}>
                        <Link to="/terms">Câu hỏi thường gặp</Link>
                    </div>
                    <div className={cx('item')}>
                        <Link to="/privacy">Câu hỏi thường gặp</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
