import classNames from 'classnames/bind';
import styles from './LineWrap.module.scss';

const cx = classNames.bind(styles);

function LineWrap({ title }) {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('line')}></div>
            <h1 className={cx('title')}>{title}</h1>
            <div className={cx('line')}></div>
        </div>
    );
}

export default LineWrap;
