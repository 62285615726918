import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router-dom';

import TelcoItem from './TelcoItem';
import styles from './Telco.module.scss';
import { requestGetTelcos } from '~/services/card';
import GameIcon from '~/components/Mobile/GameIcon';
import imageDiamond from '~/assets/image/diamond.png';
import Membership from '~/components/Mobile/Membership';
import { dispatchPostError } from '~/redux/reducer/error';
import { convertCurrency, convertDiamond } from '~/configs';
import { postCurrentPay, postSelectPay } from '~/redux/reducer/pay';

const cx = classNames.bind(styles);

function Telco() {
    const [cards, setCards] = useState(null);

    const { telco } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (telco) {
            const fetch = async () => {
                const result = await requestGetTelcos(telco);

                if (result.status === 403) {
                    localStorage.removeItem('_re');
                    navigate('/');
                    window.location.reload();
                } else if (result.status === 401) {
                    dispatch(dispatchPostError(result));
                } else if (result.status === 200) {
                    setCards(result.data);
                    dispatch(postCurrentPay(result.data.data));
                } else {
                    navigate('/app');
                }
            };
            fetch();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telco]);

    useEffect(() => {
        if (!isMobile) {
            navigate('/app');
        }
    }, [navigate]);

    const handleSelect = (telco, index) => {
        dispatch(
            postSelectPay({
                select: convertDiamond(telco.gift),
                price: telco.value,
                status: 1,
                index,
                type: 'C',
            }),
        );
    };

    return (
        <div className={cx('wrapper')}>
            <GameIcon icon={cards?.data.title} />

            <div className={cx('help')}>Chọn gói cần nạp</div>

            <ul className={cx('list')}>
                {cards?.data.value.map((telco, index) => (
                    <TelcoItem
                        key={index}
                        link="/app/buy/pay"
                        image={imageDiamond}
                        gift={convertDiamond(telco.gift)}
                        value={convertCurrency(telco.value)}
                        onClick={() => handleSelect(telco, index)}
                    />
                ))}
            </ul>

            <Membership data={cards?.members} />
        </div>
    );
}

export default Telco;
