import request from '~/utils';

export const requestGetChannels = async (source) => {
    try {
        const res = await request.get('/players/channels', {
            params: {
                region: 'VN',
                language: 'vi',
                source,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestPostCard = async (data) => {
    try {
        const res = await request.post('/chargingws/v2', data);

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetTelcos = async (telco) => {
    try {
        const res = await request.get('/players/telcos', {
            params: {
                telco,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};

export const requestGetHistories = async (region, language, account_id = null) => {
    try {
        const res = await request.get('/players/histories', {
            params: {
                region,
                language,
                account_id,
            },
        });

        return res.data;
    } catch (error) {
        return error.response?.data;
    }
};
