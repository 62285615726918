import classNames from 'classnames/bind';
import styles from './Loading.module.scss';

const cx = classNames.bind(styles);

function Loading({ height = false, active = false }) {
    return (
        <div className={cx('wrapper', active && 'active', height === '100' && 'height', height === '50' && 'vh50')}>
            <div className={cx('loading')}></div>
        </div>
    );
}

export default Loading;
