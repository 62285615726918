import { useSelector } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import publicRoutes from './routes';
import Notify from './components/Notify';
import Login from './layouts/components/Login';
import SideBar from './components/Mobile/SideBar';
import DefaultLayout from './layouts/DefaultLayout';

function App() {
    const [show, setShow] = useState(true);

    const { modal, sidebar } = useSelector((state) => state.module);
    const { notifyStatus, notifyContent } = useSelector((state) => state.setting);

    const { pathname } = window.location;

    useEffect(() => {
        if (show && notifyStatus && notifyContent && pathname === '/app') {
            document.body.classList.add('open');
        }
    }, [show, notifyStatus, pathname, notifyContent]);

    return (
        <Router>
            {modal && <Login />}
            {sidebar && <SideBar />}

            {show && notifyStatus && notifyContent && pathname === '/app' && (
                <Notify
                    onClick={() => {
                        setShow(false);
                        document.body.classList.remove('open');
                    }}
                />
            )}

            <div className="App">
                <Routes>
                    {publicRoutes.map((route, index) => {
                        let Layout = DefaultLayout;

                        if (route.layout) {
                            Layout = route.layout;
                        } else if (route.layout === null) {
                            Layout = Fragment;
                        }

                        const Page = route.component;
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Layout>
                                        <Page />
                                    </Layout>
                                }
                            />
                        );
                    })}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
