import moment from 'moment';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Footer from '../components/Footer';
import Header from '../components/Header';
import Loading from '~/components/Loading';
import { requestGetSettings } from '~/services/auth';
import { requestGetSliders } from '~/services/slider';
import styles from './DefaultLayout.module.scss';
import { setSliders } from '~/redux/reducer/slider';
import { dispatchPostError } from '~/redux/reducer/error';
import { dispatchSettings } from '~/redux/reducer/setting';

const cx = classNames.bind(styles);

function DefaultLayout({ children }) {
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { error } = useSelector((state) => state.error);
    const { websiteStatus } = useSelector((state) => state.setting);

    useEffect(() => {
        const currentDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
        const date = localStorage.getItem('_re');
        const currentDateMoment = moment(currentDate);
        const dateMoment = moment(date);

        // Nếu dateMoment quá 1 ngày thì reload lại
        if (currentDateMoment.diff(dateMoment, 'days') >= 1) {
            localStorage.removeItem('_re');
            navigate('/');
            window.location.reload();
        } else {
            const fetch = async () => {
                const setting = await requestGetSettings('VN', 'vi');

                setLoading(false);
                if (setting?.status === 403) {
                    localStorage.removeItem('_re');
                    navigate('/');
                    window.location.reload();
                } else if (setting?.status === 401) {
                    dispatch(dispatchPostError(setting));
                } else if (setting?.status === 200) {
                    dispatch(dispatchSettings(setting.data));

                    const sliders = await requestGetSliders('VN', 'vi');

                    if (setting.status === 403) {
                        localStorage.removeItem('_re');
                        navigate('/');
                        window.location.reload();
                    } else if (sliders.status === 401) {
                        dispatch(dispatchPostError(sliders));
                    } else if (sliders.status === 200) {
                        dispatch(setSliders(sliders.data));
                    }
                }
            };
            fetch();
        }

        console.log('%cMua source code liên hệ: https://t.me/x9cmx', 'color:green;font-size:1rem;font-weight:bold');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {loading ? (
                <Loading height="100" />
            ) : websiteStatus ? (
                <div className={cx('wrapper')}>
                    <Header />
                    {children}
                    <Footer />
                </div>
            ) : (
                <div className={cx('website')}>
                    <div className={cx('text', 'code')}>{error.code || '502'}</div>
                    <div className={cx('text', 'message')}>{error.message || 'Bad Gateway'}</div>
                </div>
            )}
        </Fragment>
    );
}

export default DefaultLayout;
