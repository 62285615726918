import moment from 'moment';
import classNames from 'classnames/bind';

import styles from './HistoryItem.module.scss';
import { convertCurrency, convertDiamond } from '~/configs';

import imageDiamond from '~/assets/image/diamond.png';
import imageMoths from '~/assets/image/card-moths.png';

const cx = classNames.bind(styles);

function HistoryItem({ data, userLogin = true }) {
    return (
        <li className={cx('wrapper')}>
            <div className={cx('product')}>
                <img src={data.type === 'T' ? imageMoths : imageDiamond} alt={data.type === 'T' ? 'Thẻ tuần' : 'Kim cương'} />
                <span>
                    {data.type === 'T' ? 'Thẻ tuần' : 'Kim cương'} × {convertDiamond(data.gift)}
                </span>
                <div className={cx('date')}>{moment(data.date).format('HH:mm:ss DD/MM/YYYY')}</div>
                <div className={cx('date')}>{userLogin ? `Nickname: ${data.nickname}` : `Game ID: ${data.account_id}`}</div>
            </div>
            <div className={cx('value')}>
                <span>{convertCurrency(data.price)}</span>
                <div className={cx('date')}>{data.telco}</div>
                <div
                    className={cx(
                        'result',
                        data.status === 99 || data.status === 101 || data.status === 102 || data.status === 103 || data.status === 104
                            ? 'warning'
                            : 'danger',
                    )}
                >
                    {data.status === 99 || data.status === 101 || data.status === 102 || data.status === 103 || data.status === 104
                        ? 'Chờ xử lý'
                        : data.status === 2
                        ? 'Sai mệnh giá'
                        : data.status === 1
                        ? 'Lỗi hệ thống'
                        : 'Thẻ cào sai'}
                </div>
            </div>
        </li>
    );
}

export default HistoryItem;
