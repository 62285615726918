import Home from '~/layouts/Home';
import NotFound from '~/layouts/NotFound';
import Billing from '~/components/Billing';
import Faq from '~/layouts/components/Faq';
import Navigate from '~/components/Navigate';
import Telco from '~/components/Mobile/Telco';
import Terms from '~/layouts/components/Terms';
import Privacy from '~/layouts/components/Privacy';
import History from '~/layouts/components/History';
import ConfirmPay from '~/components/Mobile/ConfirmPay';
import MobileLogin from '~/components/Mobile/MobileLogin';

const publicRoutes = [
    { path: '/faq', component: Faq },
    { path: '/app', component: Home },
    { path: '/terms', component: Terms },
    { path: '/privacy', component: Privacy },
    { path: '/billing', component: Billing },
    { path: '/login', component: MobileLogin },
    { path: '/histories', component: History },
    { path: '/app/buy/:telco', component: Telco },
    { path: '/app/buy/pay', component: ConfirmPay },
    { path: '*', component: NotFound, layout: null },
    { path: '/', component: Navigate, layout: null },
];

export default publicRoutes;
