import { createSlice } from '@reduxjs/toolkit';

const moduleReducer = createSlice({
    name: 'module',
    initialState: {
        modal: false,
        sidebar: false,
    },
    reducers: {
        openModal: (state) => {
            state.modal = true;
        },
        closeModal: (state) => {
            state.modal = false;
        },

        openSidebar: (state) => {
            state.sidebar = true;
        },
        closeSidebar: (state) => {
            state.sidebar = false;
        },
    },
});

export const { openModal, closeModal, openSidebar, closeSidebar } = moduleReducer.actions;

export default moduleReducer.reducer;
