import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import CusButton from '../CusButton';
import { convertCurrency } from '~/configs';
import styles from './ResultPay.module.scss';

import imageWarning from '../../assets/image/warning.png';
import imageSuccess from '../../assets/image/success.png';
import imageDanger from '../../assets/image/not-found.png';

const cx = classNames.bind(styles);

function ResultPay({ data }) {
    const { amount, date, image, message, select, status, telco, title: product, transId } = data;

    const { userLogin } = useSelector((state) => state.setting);

    let img_url, title;
    switch (status) {
        case 99:
            img_url = imageWarning;
            title = 'Đang xử lý!';
            break;
        case 1:
            img_url = imageSuccess;
            title = 'Xin chúc mừng!';
            break;
        default:
            img_url = imageDanger;
            title = 'Vui lòng thử lại!';
    }

    return (
        <div className={cx('wrapper')}>
            <div className={cx('alert')}>
                <div className={cx('icon')} style={{ backgroundImage: `url(${img_url})` }}></div>
                <span className={cx('title')}>{title}</span>
            </div>

            <div className={cx('content')}>
                <div className={cx('notify')}>{message}</div>

                <div className={cx('item')}>
                    <div className={cx('product')}>Sản phẩm được chọn</div>
                    <div className={cx('value')}>
                        <img src={image} alt={select} />
                        <span>
                            {product} {select}
                        </span>
                    </div>
                </div>
                <div className={cx('item')}>
                    <div className={cx('product')}>Giá</div>
                    <div className={cx('value')}>{convertCurrency(amount)}</div>
                </div>
                <div className={cx('item')}>
                    <div className={cx('product')}>Phương thức thanh toán</div>
                    <div className={cx('value')}>{telco}</div>
                </div>
                <div className={cx('item')}>
                    <div className={cx('product')}>Thời gian giao dịch</div>
                    <div className={cx('value')}>{date}</div>
                </div>
                <div className={cx('item')}>
                    <div className={cx('product')}>ID giao dịch</div>
                    <div className={cx('value')}>{transId}</div>
                </div>

                <div className={cx('btn')}>
                    {!userLogin ? (
                        <CusButton onClick={() => window.location.reload()} text="Thanh toán lại" />
                    ) : (
                        <Link to="/histories">
                            <CusButton text="Lịch sử giao dịch" />
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ResultPay;
