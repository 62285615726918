import { createSlice } from '@reduxjs/toolkit';

const errorReducer = createSlice({
    name: 'error',
    initialState: {
        error: {
            code: '',
            message: '',
        },
    },
    reducers: {
        dispatchPostError: (state, action) => {
            state.error.code = action.payload.status;
            state.error.message = action.payload.error;
        },

        dispatchRemoveError: (state) => {
            state.error.code = '';
            state.error.message = '';
        },
    },
});

export const { dispatchPostError, dispatchRemoveError } = errorReducer.actions;

export default errorReducer.reducer;
