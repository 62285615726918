import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import styles from './CusButton.module.scss';

const cx = classNames.bind(styles);

function CusButton({ text, onClick, back = false, display = false, disable = false }) {
    return (
        <Fragment>
            {back ? (
                <div className={cx('back')}>
                    <Link to="/app">
                        <div className={cx('icon-wrap')}>
                            <div className={cx('icon')}></div>
                        </div>
                    </Link>
                    <div className={cx('title')}>Quay lại Trang chủ</div>
                </div>
            ) : (
                <div className={cx('wrapper', display && 'display', disable && 'disable')} onClick={onClick}>
                    {text}
                </div>
            )}
        </Fragment>
    );
}

export default CusButton;
